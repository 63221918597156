import React from "react";
import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>FinFetch | Privacy Policy</title>
      </Helmet>
    </div>
  );
}
